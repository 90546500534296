// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import GitInfo from './git-version.json';
import Timestamp from './build-timestamp.json';

export const environment = {
	production: false,
	homeAirport: 1,
	defaultAirlineId: 11034,
	defaultAirportId: 116880,
	apiScheduleURL: 'http://dev.msural.ru:82',
	apiCommunicationURL: 'http://dev.msural.ru:83',
	apiReferanceURL: 'http://dev.msural.ru:81',
	apiAdministrationURL: 'http://dev.msural.ru:83',
	apiSlotCoordinationURL: 'http://dev.msural.ru:91',
	apiWeightBalanceURL: 'http://dev.msural.ru:1200',
	apiLostFoundURL: 'http://dev.msural.ru:1301',
	apiWorkflowURL: 'http://localhost:3500',
	apiTestURL: 'http://dev.msural.ru:86',
	apiInformationalURL: 'http://dev.msural.ru:96',
	apiTechProcessesURL: 'http://dev.msural.ru:112',
	apiBoardingControlURL: 'http://localhost/monitorsoft/get.php',
	apiReportURL: 'http://dev.msural.ru:1007',
	apiMaterialAccountingURL: 'http://dev.msural.ru:1700',
	apiJSONServer: 'http://dev.msural.ru:3000',
	apiLogServer: 'http://dev.msural.ru:3000',
	typeEnterprise: 'airport',

	keycloak: {
		// Url of the Identity Provider
		issuer: 'http://dev.msural.ru:8080/auth/',
		// Realm
		realm: 'msu',
		// The SPA's id.
		// The SPA is registerd with this id at the auth-serverß
		clientId: 'frontend',
	},
	applicationVersion: require('../../package.json').version + '-dev',
	applicationBuild: GitInfo.hash + ' ' + Timestamp.date,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
